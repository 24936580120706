<template>
  <div class="content">
    <Breadcrumb :data="nav" :is$t="true" />
    <main>
      <lawyer-card
        :detail="detail"
        :btn-text="autoPrefix('4906b41')"
        @jumpPage="jumpDetail"
      />
      <aside>
        <Card shadow="never" :body-style="{ border: '0', height: '100%' }">
          <template v-slot:header>{{ $t("6146d99") }}</template>
          <div v-if="replyContent.length" class="comment-box">
            <div
              v-for="(item, key) in replyContent"
              :key="key"
              class="comment-container"
            >
              <div v-if="item.type === 'receiver'" class="comment">
                <img class="comment-avatar" :src="item.picture" />
                <div class="comment-info">
                  <p class="info">{{ item.createTime }} {{ item.name }}</p>
                  <p class="text">
                    {{ item.replyContent }}
                  </p>
                </div>
              </div>
              <div v-if="item.flag" class="comment right">
                <div class="comment-info">
                  <p class="info">{{ item.createTime }} {{ item.name }}</p>
                  <p class="text">
                    {{ item.replyContent }}
                  </p>
                </div>
                <img class="comment-avatar" :src="item.picture" />
              </div>
            </div>
          </div>
          <template v-if="!replyContent.length">
            <el-input
              v-model="content"
              type="textarea"
              :rows="4"
              :placeholder="autoPrefix('b3771cd')"
            />
            <el-button
              v-if="!replyContent.length"
              type="primary"
              :loading="false"
              class="submit"
              @click="submitConsult"
              >{{ $t("d6421b8") }}
            </el-button>
          </template>

          <el-button
            v-if="replyContent.length && !showContent"
            type="primary"
            :loading="false"
            class="submit"
            @click="toggle(true)"
            >{{ $t("249393c") }}</el-button
          >
          <template v-if="showContent">
            <el-input
              v-if="showContent"
              v-model="content"
              type="textarea"
              :rows="4"
              :placeholder="b3771cd"
            />
            <section v-if="showContent" class="btns">
              <button class="btn-blue" @click="submitConsult">
                {{ $t("f0030c1") }}
              </button>
              <button class="btn-empty" @click="toggle(false)">
                {{ $t("6b0feee") }}
              </button>
            </section>
          </template>
        </Card>
      </aside>
    </main>
  </div>
</template>

<script>
import Api from "@/api/communication/lawyer";
import Card from "@/components/Card";
import LawyerCard from "./components/lawyerCard.vue";
import Breadcrumb from "@/components/Breadcrumb";
const nav = [
  { name: "bae89f7", path: "/communication" },
  { name: "f4ebe52", path: "/communication/lawyer" },
];
export default {
  name: "LawyerConsult",
  components: {
    LawyerCard,
    Card,
    Breadcrumb,
  },
  data() {
    return {
      Api,
      id: "",
      nav: [],
      detail: {},
      content: "",
      replyContent: [],
      showContent: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getDetail();
    this.getConsultDetail();
  },
  methods: {
    autoPrefix(name) {
      let fun = "$t";
      if (this.abroad) {
        fun = "geEn";
      }
      return this[fun](name);
    },
    toggle(booleanValue) {
      this.showContent = booleanValue;
    },
    submitConsult() {
      Api.consult(this.id, { replyContent: this.content }).then((res) => {
        if (res.code === "000000") {
          this.getConsultDetail();
          this.content = "";
          this.$message.success("咨询成功, 请到个人中心下我的消息页面查看!");
        } else {
          this.$message(res.message);
        }
      });
    },
    jumpDetail() {
      this.$router.replace({
        path: `/communication/lawyer/detail?id=${this.id}&type=${this.type}`,
      });
    },
    getDetail() {
      Api.getDetail(this.id, this.type)
        .then((res) => {
          console.log(res);
          this.detail = res;
          this.nav = [
            ...nav,
            {
              name: res.name,
              path: "",
            },
          ];
        })
        .catch(() => {});
    },
    getConsultDetail() {
      // 需求暂时先不分页
      Api.consultDetail(this.id, { size: 999999 })
        .then((res) => {
          this.replyContent = res.content;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 20px auto;
}
main {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
}
aside {
  width: 920px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgb(142 142 142 / 18%);
}
.submit {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 120px;
}

.comment {
  width: 100%;
  display: flex;
  align-items: center;
  color: #121e2c;
  &-box {
    margin-bottom: 30px;
  }
  &-container {
    margin-bottom: 20px;
  }
  .text {
    max-width: 632px;
    padding: 10px;
    line-height: 14px;
    background-color: #f7f7f7;
  }
  .info {
    color: #c3c3c3;
    font-size: 12px;
    margin-bottom: 4px;
  }
  &.right {
    justify-content: flex-end;
    .comment-avatar {
      margin-left: 20px;
    }
    .text {
      background-color: #abdaff;
    }
  }
  &-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
  }
}
.btns {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  .btn-blue {
    background-color: #1890ffff;
    color: #fff;
    margin-right: 40px;
  }
  .btn-empty {
    border: 1px solid #dddddd;
    color: #666666;
    background-color: transparent;
  }
  button {
    height: 32px;
    line-height: 32px;
    width: 120px;
    border-radius: 4px;
    text-align: center;
  }
}
</style>
